import { ref, computed, watch } from '@vue/composition-api'
import { useWorkflows } from './useWorkflows'
import { capitalize } from '@/utils/filter'
import { getUserData} from '../../utils/utils'

import ModalWorkflow from '@/components/prompt/Workflow'
import useAPI from '@/utils/useAPI'
import i18n from '@/libs/i18n'
import store from '@/store'
import router from '@/router'
import workflow from '../../store/workflow/workflow'

export default {
  components: {
    ModalWorkflow
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const currentWorkflow = ref({})
    const workflowModalShow = ref(false)
    const workflowModalTitle = ref(i18n.t('NewWorkflow'))

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const workflow = computed(() => {
      let workflow = store.getters['workflow/getWorkflow'](router.history.current.params.workflowId)

      if (typeof workflow != 'undefined') {
        workflow.tasks = workflow.tasks.filter(t => t.logs.at(-1).by.id == getUserData().id)
      }

      return workflow
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(workflowModalShow, (val) => {
      if (val == false) {
        resetWorkflow()
      }
    })

    watch(workflow, (val) => {
      fetchCalendars(val.events)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchArticles,
      fetchCompanies,
      fetchIncomingInvoices,
      fetchOffers,
      fetchOrders,
      fetchOutgoingInvoices,
      fetchPaymentMethods,
      fetchPaymentTerms,
      fetchVats,
      fetchUsers,
      fetchWorkflows,
      fetchCalendars,
    } = useAPI()

    const {
      submitValidatedWorkflow,
      activateWorkflow,
      archivateWorkflow,
      removeWorkflow,
    } = useWorkflows()

    const resetWorkflow = () => {
      workflowModalTitle.value = i18n.t('NewWorkflow')
      currentWorkflow.value = JSON.parse(JSON.stringify(store.getters['workflow/getEmptyWorkflow']))
    }

    const addWorkflow = () => {
      workflowModalShow.value = true
    }

    const selectWorkflow = (workflow) => {
      workflowModalTitle.value = i18n.t('EditWorkflow')
      currentWorkflow.value = JSON.parse(JSON.stringify(workflow))

      workflowModalShow.value = true
    }

    const duplicateWorkflow = (workflow) => {
      currentWorkflow.value = JSON.parse(JSON.stringify(workflow))
      delete currentWorkflow.value.id
      delete currentWorkflow.value.logs
      delete currentWorkflow.value.events
      delete currentWorkflow.value.incomingInvoices
      delete currentWorkflow.value.offers
      delete currentWorkflow.value.orders
      delete currentWorkflow.value.outgoingInvoices

      workflowModalShow.value = true
    }

    const submitValidatedWorkflowLocal = () => {
      submitValidatedWorkflow(currentWorkflow.value)
        .then(response => {
          // resetWorkflow()
          workflowModalShow.value = false
        })
    }

    // ========================= Start activity =========================
    const doneActivity = (activity) => {
      let act = JSON.parse(JSON.stringify(activity))
      act.isDone = true

      submitValidatedActivity(act)
    }

    const submitValidatedActivity = (activity) => {
      let editedWorkflow = JSON.parse(JSON.stringify(workflow.value))

      if ('id' in activity && activity.id != null) {
        let index = editedWorkflow.activities.findIndex(act => act.id == activity.id)
        editedWorkflow.activities.splice(index, 1, JSON.parse(JSON.stringify(activity)))
      } else {
        editedWorkflow.activities.push(activity)
      }

      submitValidatedWorkflow(editedWorkflow)

    }

    const cancelPlanifiedActivity = (activityIndex) => {
      let editedWorkflow = JSON.parse(JSON.stringify(workflow.value))
      editedWorkflow.activities.splice(activityIndex, 1)

      submitValidatedWorkflow(editedWorkflow)
    }
    // ========================= End activity =========================

    // ============================= Start notes =============================
    const submitValidatedNote = (note) => {
      let editedWorkflow = JSON.parse(JSON.stringify(workflow.value))

      if ('id' in note && note.id != null) {
        let index = editedWorkflow.notes.findIndex(nte => nte.id == note.id)
        editedWorkflow.notes.splice(index, 1, JSON.parse(JSON.stringify(note)))
      } else {
        editedWorkflow.notes.push(note)
      }

      submitValidatedWorkflow(editedWorkflow)
    }
    // ============================= End notes =============================

    const removeWorkflowLocal = (workflow) => {
      removeWorkflow(workflow)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchWorkflows().then(() => {
      // fetchCalendars(workflow)
    })

    fetchArticles()
    fetchCompanies()
    // fetchIncomingInvoices()
    // fetchOffers()
    // fetchOrders()
    // fetchOutgoingInvoices()
    fetchPaymentMethods()
    fetchPaymentTerms()
    fetchVats()
    fetchUsers()

    resetWorkflow()

    return {
      // Components
      capitalize,

      // Data
      currentWorkflow,
      workflowModalShow,
      workflowModalTitle,

      // Computed
      workflow,

      // Methods
      addWorkflow,
      selectWorkflow,
      duplicateWorkflow,
      submitValidatedWorkflowLocal,
      doneActivity,
      submitValidatedActivity,
      cancelPlanifiedActivity,
      activateWorkflow,
      archivateWorkflow,
      submitValidatedNote,
      removeWorkflowLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteWorkflowAlert (quote) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theWorkflow') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeWorkflowLocal(quote)
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}