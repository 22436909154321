<template>
  <b-col cols="12">
    <div class="d-flex justify-content-between">

      <!--        Name-->
      <field
          :name="'name'"
          :listIndex="index"
          :rules="'required'"
          :model.sync="phase.name"
          class="w-100"
      />

      <div style="margin-top: 6px" class="ml-1">
        <button-delete
            size="sm"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            :disabled="!allowRemove"
            @click.native.stop="$emit('removePhase')"
            class="mt-2"
        />
      </div>
    </div>

  </b-col>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import Field from '@/components/input/Input'
import ButtonDelete from '../../../button/Delete'

export default {
  components: {
    Field,
    ButtonDelete
  },
  props: {
    phase: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      required: true
    },
    allowRemove: {
      type: Boolean
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>