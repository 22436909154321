<template>
  <div>

    <div
        ref="formPhases"
        class="repeater-form"
        :style="{height: trHeight}"
    >
      <b-row
          v-for="(phase, index) in phases"
          :key="index"
          ref="row"
          class="pb-1"
      >
        <phase
            :phase="phase"
            :index="index"
            :allowRemove="allowRemove(index)"
            @removePhase="removePhase(index)"
            @updateSize="initTrHeight"
        />

      </b-row>
    </div>

    <button-add
        size="sm"
        @click="addNewPhase"
        :text="$t('AddPhase')"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { heightTransition } from '@/utils/form'
import Ripple from 'vue-ripple-directive'
import Phase from './components/_phase'
import store from '@/store'
import ButtonAdd from '@/components/button/Add'

export default {
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    Phase,
    ButtonAdd
  },
  props: {
    phases: {
      type: Array,
      default: () => []
    },
    allowEmpty: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const currentBreakPoint = computed(() => {
      return store.getters['app/currentBreakPoint']
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      currentBreakPoint,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    addNewPhase () {
      this.phases.push({})

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removePhase (index) {
      if (this.allowRemove(index)) {
        this.phases.splice(index, 1)

        this.$nextTick(() => {
          if (typeof this.$refs.row[0] !== 'undefined') {
            this.trTrimHeight(this.$refs.row[0].offsetHeight)
          } else {
            this.initTrHeight()
          }
        })
      }
    },
    allowRemove (index) {
      return this.allowEmpty || (!this.allowEmpty && this.phases.length > 1)
    },
    initTrHeight () {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.formPhases.scrollHeight)
      })
    },
  },
  mounted () {
    this.initTrHeight()

    this.$nextTick(() => {
      if (!this.allowEmpty && this.phases.length == 0) {
        this.phases.push({})

        this.$nextTick(() => {
          if (['md', 'lg', 'xl'].includes(this.currentBreakPoint)) {
            this.trSetHeight(this.phases.length * 87.656)
          } else {
            this.trSetHeight(this.phases.length * 800.906)
          }
        })
      }

      if (this.phases.length) {
        this.$nextTick(() => {
          if (['md', 'lg', 'xl'].includes(this.currentBreakPoint)) {
            this.trSetHeight(this.phases.length * 87.656)
          } else {
            this.trSetHeight(this.phases.length * 800.906)
          }
        })
      }

    })
  },
  created () {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.initTrHeight)
  },
}
</script>

<style lang="scss"
       scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>