import store from '@/store'
import i18n from '@/libs/i18n'

export const useWorkflows = () => {

  const submitValidatedWorkflow = (workflow) => {
    return new Promise((resolve, reject) => {
      if ('id' in workflow && workflow.id != null) {
        updateWorkflow(workflow)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewWorkflow(workflow)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewWorkflow = (workflow) => {
    return new Promise((resolve, reject) => {
      store.dispatch('workflow/addWorkflow', workflow)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateWorkflow = (workflow) => {
    return new Promise((resolve, reject) => {
      store.dispatch('workflow/updateWorkflow', workflow)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const activateWorkflow = (workflow) => {
    workflow.isArchived = false

    updateWorkflow(workflow)
  }

  const archivateWorkflow = (workflow) => {
    workflow.isArchived = true

    updateWorkflow(workflow)
  }

  const allowDelete = (workflow) => {
    return workflow.events.length == 0 &&
      workflow.incomingInvoices.length == 0 &&
      workflow.orders.length == 0 &&
      workflow.outgoingInvoices.length == 0 &&
      workflow.offers.length == 0
  }

  const removeWorkflow = (workflow) => {
    return new Promise((resolve, reject) => {
      store.dispatch('workflow/removeWorkflow', workflow)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  return {
    submitValidatedWorkflow,
    activateWorkflow,
    archivateWorkflow,
    allowDelete,
    removeWorkflow,
  }
}