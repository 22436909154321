<template>
  <b-modal
      id="1"
      :title="title"
      v-model="isOpenLocal"
      size="lg"
      scrollable
      hide-footer
      @hide="tryCloseWorkflowModal"
  >
    <validation-observer #default="{handleSubmit}">
      <b-form @submit.prevent="handleSubmit(submitValidatedWorkflowLocal)">
        <form-workflow :workflow="workflowLocal"/>
        <button ref="submitWorkflowFormRef"
                class="d-none"></button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'

import FormWorkflow from '@/components/form/Workflow'

export default {
  components: {
    ValidationObserver,
    FormWorkflow
  },
  props: {
    workflow: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const workflowLocal = ref(props.workflow)
    const submitWorkflowFormRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })
    watch(workflowLocal, (val) => {
      emit('update:workflow', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryCloseWorkflowModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitWorkflowFormRef.value.click()
      }
    }

    const submitValidatedWorkflowLocal = () => {
      emit('submitValidatedWorkflow')
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isOpenLocal,
      workflowLocal,
      submitWorkflowFormRef,

      // Computed

      // Methods
      tryCloseWorkflowModal,
      submitValidatedWorkflowLocal
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    workflow: function (val) {
      this.workflowLocal = val
    },
    isOpen: function (val) {
      this.isOpenLocal = val
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>