<template>
  <generic-card
      @click="$emit('click')"
      @delete="$emit('deleteWorkflow')"
      :allowDelete="allowDelete(workflow)"
  >

    <template #iconContent>
      <b-avatar
          size="32"
          variant="light-primary"
      >
        <icon icon="paper-plane"/>
      </b-avatar>
    </template>

    <template #bodyContent>
      <h4>{{ workflow.opportunity }}</h4>

      <div class="truncate">
        <span v-if="contact">{{ contact._display }}</span>
        <span v-else>&nbsp;</span>
      </div>
      <div
          v-if="workflow.closingDate"
          class="truncate d-flex justify-content-between">
        <span>{{ workflow.closingDate | moment('L') }}</span>
      </div>
      <div v-else>&nbsp;</div>

    </template>
  </generic-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { useWorkflows } from '@/views/workflows/useWorkflows'

import GenericCard from './Card'

export default {
  components: {
    GenericCard
  },
  props: {
    workflow: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const contact = computed(() => {
      if (props.workflow.customerCompany != null) {
        return props.workflow.customerCompany
      } else if (props.workflow.customerIndividual != null) {
        return props.workflow.customerIndividual
      } else {
        return null
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { allowDelete } = useWorkflows()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      contact,

      // Computed

      // Methods
      allowDelete,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>