<template>
  <div>
    <search-filter
        :items="workflows"
        :keyTitleForAlphabeticalSort="'opportunity'"
        :additionalSortOptions="additionalSortOptions"
        :selectedFilters="['_filter_isActive']"
        :selectedView="'list'"
        :excludedKeys="['workflows']"

        :tableColumns="tableColumns"
        :isActionColumnEnabled="true"
        @columnClick="$event._isDisabled?activateWorkflow($event):( $router.push({ name: 'Workflow view', params: { workflowId: $event.id } }))"
        @addItem="addWorkflow"
    >
      <!--      Grid view-->
      <template v-slot:gridView="{item}">
        <card-workflow
            :workflow="item"
            @click="selectWorkflow(item)"
            @deleteWorkflow="deleteWorkflowAlert(item)"
        />
      </template>

      <!--      List view-->

      <!--      Cell contact-->
      <template #listView_cell_contact="{item}">
        {{
          item.customerCompany ? item.customerCompany._display : (item.customerIndividual ? item.customerIndividual._display : '-')
        }}
      </template>

      <!--      Cell closing date-->
      <template #listView_cell_closingDate="{item}">
        <span v-if="item.closingDate">{{ item.closingDate|moment('L') }}</span>
        <span v-else>-</span>
      </template>

      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          Activate-->
          <button-unarchivate
              v-if="item.isArchived"
              @click.native.stop="activateWorkflow(item)"
              v-b-tooltip.hover.left="capitalize($t('Unarchive'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />
          <!--          <icon-->
          <!--              v-if="item.isArchived"-->
          <!--              @click.native.stop="activateWorkflow(item)"-->
          <!--              v-b-tooltip.hover.left="capitalize($t('Unarchive'))"-->
          <!--              icon="box-open"-->
          <!--              class="cursor-pointer px-50"/>-->

          <!--          View-->
          <button-view
              v-if="!item.isArchived"
              @click.native.stop="$router.push({ name: 'Workflow view', params: { workflowId: item.id }})"
              v-b-tooltip.hover.left="capitalize($t('View'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />
          <!--          <icon-->
          <!--              v-if="!item.isArchived"-->
          <!--              @click.native.stop="$router.push({ name: 'Workflow view', params: { workflowId: item.id }})"-->
          <!--              v-b-tooltip.hover.left="capitalize($t('View'))"-->
          <!--              icon="eye"-->
          <!--              class="cursor-pointer px-50"/>-->

          <!--          Edit-->
          <button-edit
              v-if="!item.isArchived"
              @click.native.stop="selectWorkflow(item)"
              v-b-tooltip.hover.left="capitalize($t('Edit'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />
          <!--          <icon-->
          <!--              v-if="!item.isArchived"-->
          <!--              @click.native.stop="selectWorkflow(item)"-->
          <!--              v-b-tooltip.hover.left="capitalize($t('Edit'))"-->
          <!--              icon="edit"-->
          <!--              class="cursor-pointer px-50"/>-->

          <b-dropdown
              v-if="!item.isArchived"
              variant="link"
              toggle-class="p-0"
              no-caret
              right
          >
            <template #button-content>
              <button-dropdown
                  :withIcon="true"
                  :withText="false"
                  :withBorder="false"
                  size="sm"
              />
            </template>

            <!--          Duplicate -->
            <b-dropdown-item
                @click.stop="duplicateWorkflow(item)"
            >
              <icon
                  icon="copy"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('duplicate')) }}</span>
            </b-dropdown-item>

            <!--          Archivate-->
            <b-dropdown-item
                @click.stop="archivateWorkflow(item)"
            >
              <icon
                  icon="box"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('Archivate')) }}</span>
            </b-dropdown-item>

            <!--          Delete -->
            <b-dropdown-item
                v-if="item.number ==null"
                @click.stop="deleteWorkflowAlert(item)"
            >
              <icon
                  icon="times"
                  class="cursor-pointer"/>
              <span class="align-middle ml-1">{{ capitalize($t('delete')) }}</span>
            </b-dropdown-item>

          </b-dropdown>

        </div>
      </template>

    </search-filter>

    <!-- modal workflow-->
    <modal-workflow
        :workflow.sync="currentWorkflow"
        :title="workflowModalTitle"
        :isOpen.sync="workflowModalShow"
        @submitValidatedWorkflow="submitValidatedWorkflowLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import mixinWorkflows from './mixinWorkflows'
import CardWorkflow from '@/components/card/Workflow'
import useAPI from '@/utils/useAPI'
import SearchFilter from '@/components/searchFilter/SearchFilter'
import i18n from '@/libs/i18n'
import ButtonUnarchivate from '@/components/button/Unarchivate'
import ButtonEdit from '@/components/button/Edit'
import ButtonDropdown from '@/components/button/Dropdown'
import ButtonView from '@/components/button/View'
import router from '@/router'

export default {
  components: {
    SearchFilter,
    CardWorkflow,
    ButtonUnarchivate,
    ButtonEdit,
    ButtonDropdown,
    ButtonView
  },
  mixins: [mixinWorkflows],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const additionalSortOptions = ref([])
    const tableColumns = ref([
      { key: 'opportunity', label: i18n.t('opportunity'), sortable: true },
      { key: 'closingDate', label: i18n.t('closingDate'), sortable: true },
      // { key: 'status', label: i18n.t('status'), sortable: false },
      { key: 'contact', label: i18n.t('contact'), sortable: true },
    ])

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { workflows, fetchCalendars } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(workflows, () => {
      if (workflows.value.length) {
        workflows.value[0]._filters.forEach(filter => {
          if (additionalSortOptions.value.some(el => el.key == filter.key) == false) {
            additionalSortOptions.value.push(filter)
          }
        })
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    // const selectWorkflow = (workflow) => {
    //   router.push({ name: 'Workflow view', params: { workflowId: workflow.id } })
    // }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchCalendars()

    return {
      // Components

      // Data
      additionalSortOptions,
      tableColumns,

      // Computed
      workflows,

      // Methods
      // selectWorkflow,

    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>